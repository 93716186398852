<template>
  <div>
    <div class="d-flex">
      <GenericButtonDownload
        :tooltip_text="`${$getVisibleNames(
          'mesh.career',
          true,
          'Programas'
        )} XLSX`"
        :click_button="DownloadCareersXLS"
        :icon_excel="true"
        :class="`ml-auto mb-2 mr-3`"
      ></GenericButtonDownload>
      <!-- <b-button
        variant="primary"
        class="mr-3 mb-2 ml-auto btn noprint"
        @click="DownloadCareersXLS"
      >
        <b-icon-download class="mr-1 icon-download"></b-icon-download>
        {{ $getVisibleNames("mesh.career", true, "Programas") }} XLS
      </b-button> -->
    </div>
    <div>
      <b-table-simple
        bordered
        no-border-collapse
        small
        responsive
        :sticky-header="stickyHeaderSize"
      >
        <b-thead>
          <b-tr>
            <b-th class="text-center align-middle primary-header">
              Número de Currículum
            </b-th>
            <b-th class="text-center align-middle primary-header">
              {{ $getVisibleNames("mesh.career", false, "Programa") }}
            </b-th>
            <b-th class="text-center align-middle primary-header">
              {{ $getVisibleNames("mesh.faculty", false, "Escuela") }}
            </b-th>
            <b-th class="text-center align-middle primary-header">
              Entrada Vigencia
            </b-th>
            <b-th class="text-center align-middle primary-header"> Nivel </b-th>
            <b-th
              v-if="institution && institution.internal_use_id == 'duoc_uc'"
              class="text-center align-middle primary-header"
            >
              Jornada
            </b-th>
            <b-th class="text-center align-middle primary-header">
              Modalidad
            </b-th>
            <b-th class="text-center align-middle primary-header">
              Título que otorga
            </b-th>
            <b-th class="text-center align-middle primary-header">
              Duración
            </b-th>
            <b-th
              v-if="institution && institution.internal_use_id == 'duoc_uc'"
              class="text-center align-middle primary-header"
            >
              Área de escuela
            </b-th>
            <b-th
              v-if="institution && institution.internal_use_id == 'duoc_uc'"
              class="text-center align-middle primary-header"
            >
              KF
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-if="careers_report == null || careers_report.length == 0">
            <b-tr>
              <b-td
                :colspan="
                  institution && institution.internal_use_id == 'duoc_uc'
                    ? 11
                    : 8
                "
              >
                <strong> No hay datos para mostrar </strong>
              </b-td>
            </b-tr>
          </template>
          <template v-else-if="careers_report.length > 0">
            <b-tr
              v-for="egress_profile in careers_report"
              :key="egress_profile.id"
            >
              <!-- Número de Currículum -->
              <b-td class="text-center aling-middle">{{
                egress_profile.egress_profile_code
              }}</b-td>
              <!-- Carrera -->
              <b-td class="text-left">{{ egress_profile.career_name }} </b-td>
              <!-- Escuela -->
              <b-td class="text-left">{{ egress_profile.faculty_name }} </b-td>
              <!-- Entrada Vigencia -->
              <b-td class="text-center aling-middle"
                >{{ egress_profile.egress_profile_ini_date }}
              </b-td>
              <!-- Nivel -->
              <b-td class="text-center aling-middle"
                >{{ egress_profile.egress_profile_type_name }}
              </b-td>
              <!-- jornada -->
              <b-td
                v-if="institution && institution.internal_use_id == 'duoc_uc'"
                class="text-center aling-middle"
                >{{ egress_profile.ep_shift }}
              </b-td>
              <!-- Modalidad -->
              <b-td class="text-center aling-middle"
                >{{ egress_profile.ep_modality }}
              </b-td>
              <!-- Título que otorga -->
              <b-td class="text-left"
                >{{ egress_profile.professional_title }}
              </b-td>
              <!-- Duración -->
              <b-td class="text-center aling-middle"
                >{{ egress_profile.semester_amount }}
              </b-td>
              <!-- Área de escuela -->
              <b-td
                v-if="institution && institution.internal_use_id == 'duoc_uc'"
                class="text-center"
                >{{ egress_profile.faculty_area_name }}
              </b-td>
              <!-- KF -->
              <b-td
                v-if="institution && institution.internal_use_id == 'duoc_uc'"
                class="text-left"
                >{{ egress_profile.kl_column }}
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "CareerReportView",
  components: {
    GenericButtonDownload: () =>
      import("@/components/reusable/Buttons/GenericButtonDownload"),
  },
  props: {},
  data() {
    return {
      careers_report: null,
      stickyHeaderSize: "1000px",
    };
  },
  computed: {
    ...mapGetters({ institution: "getInstitution" }),
  },
  methods: {
    getCareerReport() {
      this.$restful.Post(`/mesh/report_career_repository/`).then((response) => {
        this.careers_report = response;
      });
    },
    DownloadCareersXLS() {
      this.$restful
        .Post(`/mesh/excel_report_career_repository/`)
        .then((response) => {
          if (response && response.file_url)
            window.open(response.file_url, "_blank");
        });
    },
    updateStickyHeaderSize() {
      const viewportHeight = window.innerHeight;
      const newStickyHeaderSize = viewportHeight * 1 + "px"; // Por ejemplo, 100% de la altura del viewport
      this.stickyHeaderSize = newStickyHeaderSize;
    },
  },
  mounted() {
    this.updateStickyHeaderSize();
    window.addEventListener("resize", this.updateStickyHeaderSize);
  },
  created() {
    this.getCareerReport();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateStickyHeaderSize);
  },
};
</script>

<style scoped>
.primary-header {
  background: #d0cece !important;
}
</style>

